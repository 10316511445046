.filter-group {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 8px;
    align-items: flex-end;
}

.filter-group div > input,
.filter-group div.p-dropdown,
.filter-group div.p-multiselect,
.filter-group div.p-chips ul {
    width: 100%;
}
