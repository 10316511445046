.p-sidebar-header {
    height: 40px;
    padding: 1rem 1.25rem;
    position: absolute;
    right: 0;
}

.p-sidebar-content {
    overflow-y: hidden !important;
}

.hoverable-link:hover {
    text-decoration: underline;
    display: block;
}